<template>
  <!-- dialog S&K -->
  <v-dialog width="auto" v-model="dialogSyarat">
    <v-card max-width="90vw">
      <v-card-text>
        <div class="text-h5 font-weight-bold">
          {{ $t("toc.title") }}
        </div>
      </v-card-text>

      <v-card-text>
        <div class="font-weight-medium">
          {{ $t("toc.update-info", { date: "21 September 2023" }) }}
        </div>
      </v-card-text>

      <v-card-text>
        <div class="mb-5">
          {{ $t("toc.descriptions[0]") }}
        </div>
        <div>
          {{ $t("toc.descriptions[1]") }}
        </div>
      </v-card-text>

      <template v-for="syarat in syaratKetentuan" :key="syarat">
        <syarat-ketentuan :syarat="syarat"></syarat-ketentuan>
      </template>

      <v-card-actions>
        <v-spacer> </v-spacer>
        <v-btn @click="dialogSyarat = false"> Tutup </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SyaratKetentuan from "./SyaratKetentuanItem.vue";
export default {
  components: {
    SyaratKetentuan,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      syaratKetentuan: [],
      dialogSyarat: false,
    };
  },
  watch: {
    dialogSyarat(value) {
      this.$emit("update:dialog", value);
    },
    dialog(value) {
      if (value) {
        this.getSyaratKetentuan();
      }
    },
  },
  methods: {
    getSyaratKetentuan() {
      this.axios
        .get("info/syaratketentuan/get")
        .then((response) => {
          const data = response.data.response;
          this.syaratKetentuan = this.structuredSyaratKetentuan(data).data;
        })
        .finally(() => {
          this.dialogSyarat = true;
        });
    },

    structuredSyaratKetentuan(syaratKetentuan) {
      const temp = [];
      const blackList = [];

      for (const syarat of syaratKetentuan) {
        const id = syarat.sk_id;
        const subSyarat = syaratKetentuan.filter((s) => s.sksub_id == id);

        blackList.push(...subSyarat.map((s) => s.sk_id));

        const checkSubSyarat = this.structuredSyaratKetentuan(subSyarat);
        if (checkSubSyarat.data.length != 0) {
          syarat.contents = checkSubSyarat.data;
          blackList.push(...checkSubSyarat.blackList);
        }

        temp.sort((a, b) => {
          return a.order - b.order;
        });

        temp.push(syarat);
      }

      return {
        data: temp.filter((s) => !blackList.includes(s.sk_id)),
        blackList: blackList,
      };
    },
  },
};
</script>

<style>
</style>