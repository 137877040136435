<template>
  <v-card-text>
    <div class="text-h6 font-weight-bold mb-3">
      <span v-if="index"> {{ index + "." }}</span> {{ syarat.title }}
    </div>

    <div v-html="syarat.description" class="mb-3 text-body-1"></div>

    <div class="mb-3 text-body-1">
      <div
        v-for="(explanation, i) in syarat.explanation"
        :key="i"
        class="mb-1 ms-4"
        v-html="`${i + 1}. ` + explanation"
      ></div>
    </div>

    <template v-if="syarat.contents">
      <template v-for="(syarat, index) in syarat.contents" :key="index">
        <syarat-ketentuan-item
          :index="index + 1"
          :syarat="syarat"
        ></syarat-ketentuan-item>
      </template>
    </template>
  </v-card-text>
</template>

<script>
export default {
  name: "syarat-ketentuan-item",
  props: {
    syarat: Object,
    index: Number,
  },
};
</script>

<style>
</style>