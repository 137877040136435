<template>
  <v-container class="pa-0">
    <v-row :style="{ height: '100vh' }" class="justify-center align-center ma-auto">
      <v-col cols="12">
        <v-sheet max-width="1000" class="mx-auto">
          <v-card elevation="6">
            <v-row no-gutters align="center" justify="center">
              <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                <router-link :to="{ name: 'home' }">
                  <v-img @contextmenu.prevent class="mx-auto" src="@/assets/logo.svg" width="180" height="100"></v-img>
                </router-link>
                <v-card-text class="text-white text-center">
                  <p class="text-h6 text-center text-primary">
                    <strong>Lupa Kata Sandi?</strong> <br />
                    Jangan khawatir, e-tix bisa membantumu masuk lagi ke akun kamu
                  </p>
                </v-card-text>

                <v-img @contextmenu.prevent class="mx-auto" src="@/assets/png/forgot-password.png"
                  lazy-src="@/assets/png/forgot-password.png" width="350" height="350"></v-img>
              </v-col>

              <v-col cols="12" md="6">
                <v-card-text>
                  <ErrorHandling v-if="error" :error="error" />
                  <p class="text-h6 text-primary font-weight-bold">
                    Atur Ulang Kata Sandi
                  </p>
                  <p class="text-body-2 text-grey">
                    Kami akan mengirim kode OTP ke email kamu sehingga kamu bisa
                    membuat kata sandi baru
                  </p>
                  <v-divider class="my-2"></v-divider>
                  <v-row align="center">
                    <v-col cols="12" sm="11">
                      <v-form ref="reset" v-model="valid" lazy-validation>
                        <v-text-field @keydown.enter.prevent="checkEmail" v-model="input.email" :rules="rules.email"
                          label="Email"
                          hint="Masukkan email/nomor telepon dan kata sandi yang kamu gunakan saat mendaftar"
                          type="email" variant="outlined" dense color="primary" required />

                        <v-btn :loading="isProcessing" :disabled="isProcessing || !valid" @click.prevent="checkEmail"
                          class="text-capitalize" :color="isProcessing || !valid ? 'grey' : 'primary'" block>
                          Kirim
                        </v-btn>
                      </v-form>

                      <v-card-text>
                        <h6 class="text-center text-grey">
                          Butuh bantuan?
                          <v-btn class="font-weight-bold text-none pa-0" size="x-small" color="primary" variant="text"
                            :to="{ name: 'help' }">Tanyakan pada kami</v-btn>
                        </h6>
                      </v-card-text>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
          <footer-copyright />
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ErrorHandling from "@/components/Error.vue";
import isEmail from "validator/lib/isEmail";
import FooterCopyright from "@/components/FooterCopyright.vue";

export default {
  name: "ResetRequest",

  components: {
    ErrorHandling,
    FooterCopyright
  },

  data() {
    return {
      valid: false,
      input: {
        email: "",
      },
      rules: {
        email: [
          (v) => !!v || "Email wajib diisi",
          (v) => isEmail(v) || "Email harus valid",
        ],
      },
      error: "",
      isProcessing: false,
    };
  },

  methods: {
    async checkEmail() {
      this.isProcessing = true;
      const { valid } = await this.$refs.reset.validate();
      if (valid) {
        await this.axios
          .post("user/email", {
            user_email: this.input.email,
          })
          .then(async (response) => {
            const data = response.data;
            if (!data.error) {
              if (data.response.user_id) {
                const store_data = {
                  user_id: data.response.user_id,
                  user_email: this.input.email,
                };
                this.$store.dispatch("stateResetUser", store_data);
                await this.axios
                  .post("user/otp/send", {
                    email_receiver: this.input.email,
                    user_id: data.response.user_id,
                  })
                  .then((response) => {
                    if (!response.data.error) {
                      this.$store.dispatch("stateResetOTP", true);
                      this.isProcessing = false;
                    }
                  })
                  .catch(() => {
                    this.error = "Service sedang bermasalah!";
                  })
                  .finally(() => (this.isProcessing = false));
              }
            }
          })
          .catch(() => {
            this.error = "Email tidak terdaftar!";
          })
          .finally(() => (this.isProcessing = false));
      }
    },
  },
};
</script>
