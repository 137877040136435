<template>
  <v-app-bar
    app
    color="primary-container-dark"
    class="px-xxl-16 px-xl-16 px-lg-16 px-md-16"
  >
    <template v-slot:prepend>
      <router-link to="/">
        <v-img
          @contextmenu.prevent
          src="@/assets/logo_white_orange.svg"
          lazy-src="@/assets/logo_white_orange.svg"
          width="auto"
        />
      </router-link>
    </template>

    <v-app-bar-title>
      <v-row>
        <v-spacer></v-spacer>

        <v-col cols="10" xxl="4" xl="4" lg="4" md="4" sm="10" xs="10">
          <v-text-field
            @keyup.enter.prevent="toSearch()"
            v-model="search"
            :prepend-inner-icon="search ? 'mdi-arrow-left' : 'mdi-magnify'"
            density="compact"
            variant="solo"
            clear-icon="mdi-close-circle"
            clearable
            hide-details
            label="Cari tiketmu"
            single-line
            type="search"
          ></v-text-field>
        </v-col>

        <v-spacer></v-spacer>
      </v-row>
    </v-app-bar-title>

    <template v-slot:append>
      <div
        class="align-center"
        v-if="(loading.user || loading.cartBadge || user) && !isMobile"
      >
        <v-skeleton-loader
          v-if="loading.cartBadge"
          color="primary-container-dark"
          max-height="40"
          max-width="100"
          height="40"
          width="100"
          type="chip"
          class="mx-auto mb-6"
        ></v-skeleton-loader>
        <v-btn
          v-else-if="user"
          rounded="lg"
          class="text-none"
          :to="{ name: 'cart' }"
          selected-class="bg-primary"
          stacked
        >
          <v-badge
            v-if="cartBadgeContent"
            :content="cartBadgeContent"
            color="secondary-60"
          >
            <v-icon>mdi-cart</v-icon>
          </v-badge>

          <v-icon v-else>mdi-cart</v-icon>
        </v-btn>

        <v-skeleton-loader
          v-if="loading.user"
          color="primary-container-dark"
          max-height="50"
          max-width="300"
          height="50"
          width="150"
          type="button"
          class="mx-auto mb-6"
        ></v-skeleton-loader>
        <v-menu v-else-if="user" v-model="menu" open-on-click>
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              class="mx-2 py-2 text-capitalize"
              rounded="lg"
              size="large"
              variant="tonal"
            >
              {{ this.user?.user_firstname }}
              <template v-slot:append>
                <v-icon v-if="!menu">mdi-chevron-down</v-icon>
                <v-icon v-else>mdi-chevron-up</v-icon>
              </template>

              <template v-slot:prepend>
                <v-icon>mdi-account</v-icon>
              </template>
            </v-btn>
          </template>

          <v-card min-width="250" class="mx-auto">
            <v-list density="compact">
              <v-list-item
                :prepend-avatar="this.user?.photo_id"
                :title="this.user?.user_firstname"
                :subtitle="this.user?.level?.level_name"
              >
              </v-list-item>
              <v-list-item
                :to="{ name: 'profile' }"
                prepend-icon="mdi-account"
                title="Profil Saya"
              />
              <v-list-item
                :to="{ name: 'my-order' }"
                prepend-icon="mdi-ticket-confirmation"
                title="Pesanan Saya"
              />
              <v-list-item
                :to="{ name: 'bookmark' }"
                prepend-icon="mdi-bookmark-box-multiple"
                title="Disimpan"
              />
              <v-list-item
                :to="{ name: 'setting' }"
                prepend-icon="mdi-cog"
                title="Pengaturan"
              />
              <v-list-item
                @click="logout()"
                prepend-icon="mdi-logout"
                title="Keluar"
              />
            </v-list>
          </v-card>
        </v-menu>
      </div>

      <div class="align-center" v-else-if="!user && !isMobile">
        <v-btn
          to="/login"
          @click="lastRoute()"
          class="mx-2 py-2 px-4 text-body-2 text-white text-capitalize"
          rounded="lg"
          elevation="2"
          size="medium"
          variant="outlined"
          >Masuk</v-btn
        >
        <v-btn
          to="/register"
          class="mx-2 py-2 px-4 text-body-2 text-primary text-capitalize"
          rounded="lg"
          elevation="2"
          size="medium"
          variant="flat"
          >Daftar</v-btn
        >
      </div>

      <v-app-bar-nav-icon
        v-if="isMobile"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
    </template>
  </v-app-bar>

  <v-system-bar window color="bg-primary-dark">
    <v-spacer></v-spacer>
    <v-btn
      @:click="goToURL(url.web_seller)"
      class="text-none text-body-2 pa-0 ms-2"
      variant="text"
      >Buat Event</v-btn
    >
    <v-btn
      :to="{ name: 'price' }"
      class="text-none text-body-2 pa-0 ms-2"
      variant="text"
      >Biaya</v-btn
    >
    <v-btn
      :to="{ name: 'contact' }"
      class="text-none text-body-2 pa-0 ms-2 mr-xxl-16 mr-xl-16 mr-lg-16 mr-md-16 pr-xxl-10 pr-xl-10 pr-lg-10 pr-md-10"
      variant="text"
      >Hubungi Kami</v-btn
    >
  </v-system-bar>

  <v-navigation-drawer v-if="isMobile" location="right" v-model="drawer">
    <v-list v-if="!user" nav>
      <v-list-item>
        <div class="d-flex ma-2 justify-center align-center">
          <v-btn
            to="/login"
            @click="lastRoute()"
            class="mx-2 py-2 px-4 text-body-2 text-primary text-capitalize"
            rounded="lg"
            size="medium"
            variant="outlined"
            >Masuk</v-btn
          >
          <v-btn
            to="/register"
            class="mx-2 py-2 px-4 text-body-2 text-white bg-primary text-capitalize"
            rounded="lg"
            size="medium"
            variant="flat"
            >Daftar</v-btn
          >
        </div>
      </v-list-item>
    </v-list>

    <v-list v-else nav>
      <v-list-item
        :prepend-avatar="this.user?.photo_id"
        :title="this.user?.user_firstname"
        :subtitle="this.user?.level?.level_name"
      >
      </v-list-item>

      <v-list-item
        :to="{ name: 'profile' }"
        prepend-icon="mdi-account"
        title="Profil Saya"
        exact
      />

      <v-list-item
        :to="{ name: 'my-order' }"
        prepend-icon="mdi-ticket-confirmation"
        title="Pesanan Saya"
        exact
      />

      <v-list-item
        :to="{ name: 'bookmark' }"
        prepend-icon="mdi-bookmark-box-multiple"
        title="Disimpan"
        exact
      />

      <v-list-group value="settings">
        <template v-slot:activator="{ props }">
          <v-list-item
            prepend-icon="mdi-cog"
            v-bind="props"
            title="Pengaturan"
            value="setting"
            exact
          ></v-list-item>
        </template>

        <v-list-item
          title="Ubah Kata Sandi"
          :to="{ name: 'reset-password' }"
          value="reset-password"
          exact
        ></v-list-item>

        <!-- <v-list-item
          title="Preferensi"
          value="preference-settings"
          disabled
          :to="{ name: 'user-pref' }"
        ></v-list-item>
        <v-list-item
          title="Notifikasi"
          disabled
          value="notification-settings"
          :to="{ name: 'notification-setting' }"
        ></v-list-item> -->
      </v-list-group>

      <v-list-item
        title="Keranjang"
        prepend-icon="mdi-cart"
        :to="{ name: 'cart' }"
        exact
      ></v-list-item>

      <v-list-item @click="logout()" prepend-icon="mdi-logout" title="Keluar" />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import Cookies from "js-cookie";

export default {
  name: "HeaderTix",

  computed: {
    isMobile() {
      return this.$vuetify.display.mobile;
    },

    user() {
      return this.$store.state.user;
    },

    countCartChange() {
      return this.$store.state.countCartChange;
    },

    loadingState() {
      return this.loading.user || this.loading.cartBadge;
    },
  },
  async created() {
    this.search = this.$route.query?.q;

    const cookiesUser = Cookies.get("user_data");
    if (cookiesUser) {
      this.loading.user = true;
      const user = JSON.parse(cookiesUser);
      this.axios
        .get("user/" + user.user_id)
        .then((response) => {
          this.$store.dispatch("loadUser", response.data.response);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loading.user = false));
      this.getCartBadge(user.user_id);
    }
  },

  data() {
    return {
      drawer: false,
      menu: false,
      items: [{ logout: "Keluar", icon: "mdi-logout" }],
      url: {
        web_seller: process.env.VUE_APP_WEB_SELLER_URL,
      },
      cartBadgeContent: null,
      search: null,
      searchData: {
        productName: this.search,
        pageSize: 60,
        page: 1,
      },
      snackbar: {
        status: false,
        text: "",
      },
      loading: {
        user: false,
        cartBadge: false,
      },
    };
  },

  mounted() {
    this.searchData.productName = this.search;
  },

  methods: {
    goToURL(url) {
      window.open(url, "_blank");
    },

    getCartBadge(user_id) {
      this.loading.cartBadge = true;
      this.axios
        .get(`cart/view/${user_id}`)
        .then((response) => {
          const data = response.data.response.carts;
          if (data.length) {
            let count = 0;
            data.map((product) => {
              product.product_types.map((productType) => {
                const rejected =
                  productType.is_active == false ||
                  productType.is_deleted == true ||
                  product.is_deleted == true ||
                  product.is_active == false ||
                  (productType.is_limited == true &&
                    (productType.producttype_qty -
                      productType.producttype_booked_qty <=
                      0 ||
                      productType.producttype_qty -
                        productType.producttype_booked_qty <
                        productType.qty));

                if (!rejected) {
                  count += 1;
                }
              });
            });

            if (count > 99) {
              this.cartBadgeContent = "99+";
            } else if (count === 0) {
              this.cartBadgeContent = null;
            } else {
              this.cartBadgeContent = count;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading.cartBadge = false;
        });
    },

    async logout() {
      this.$store.commit("logout");
      const tkngst = await this.axios.post("auth/guest");
      this.$store.dispatch("guest", tkngst.data.response.guest_token);
      this.$router.go(this.$route.fullPath);
    },

    lastRoute() {
      localStorage.setItem("LAST_ROUTE", this.$route.name);
    },

    toHome() {
      this.$router.push({ name: "home" });
    },

    toSearch() {
      this.$router.push({ name: "search", query: { q: this.search } });
    },
  },

  watch: {
    countCartChange: {
      deep: true,
      handler(value) {
        if (value) {
          this.getCartBadge(this.user.user_id);
          this.$store.state.countCartChange = false;
        }
      },
    },
  },
};
</script>

<style scoped>
.logo-cursor {
  cursor: pointer;
}

.search-bar {
  width: 300px;
}

.primary-e-tix {
  background: rgb(var(--v-theme-primary));
}

.primary-outline-e-tix {
  border-color: rgb(var(--v-theme-primary));
}
</style>
