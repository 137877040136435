<template>
  <v-container class="pa-0">
    <v-row :style="{ height: '100vh' }" class="justify-center align-center ma-auto">
      <v-col cols="12">
        <v-sheet max-width="1000" class="mx-auto">
          <v-card elevation="6">
            <v-row no-gutters align="center" justify="center">
              <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                <router-link :to="{ name: 'home' }">
                  <v-img @contextmenu.prevent class="mx-auto" src="@/assets/logo.svg" width="180" height="100"></v-img>
                </router-link>

                <v-img class="mx-auto" src="@/assets/png/forgot-password.png" width="350" height="350"></v-img>
              </v-col>

              <v-col cols="12" md="6">
                <v-card-text>
                  <v-alert v-if="error.show" type="error"> {{ error.text }} </v-alert>
                  <p class="text-h6 text-primary font-weight-bold">
                    Masukkan Kode OTP
                  </p>
                  <p class="text-body-2 text-grey">
                    Kamu perlu memasukkan kode OTP yang telah kami kirimkan ke emailmu
                    sebelum mengatur ulang kata sandi
                  </p>
                  <v-divider class="my-2"></v-divider>
                  <v-row align="center">
                    <v-col cols="12" sm="11">
                      <v-form ref="otp" v-model="valid" lazy-validation>
                        <v-otp-input @change="error.show = false" @keydown.enter.prevent="handleSubmit"
                          :error="error.show" :loading="isProcessing" v-model="otp" type="number"></v-otp-input>
                        <v-btn :loading="isProcessing" :disabled="isProcessing" @click="handleSubmit"
                          class="text-capitalize" color="primary" block>
                          Verifikasi
                        </v-btn>
                      </v-form>

                      <v-card-text class="text-center">
                        <h6 v-if="this.otp_time >= 0" class="text-grey">
                          Mengirim ulang OTP dalam
                          <span class="text-red">
                            {{ this.otp_time_output }}
                          </span>
                        </h6>
                        <h6 v-else-if="this.otp_time <= 0" @click="resendOTP()" class="text-grey">
                          Tidak menerima kode?
                          <v-btn class="font-weight-bold text-none pa-0" size="x-small" color="primary"
                            variant="text">Kirim
                            Ulang OTP</v-btn>
                        </h6>
                      </v-card-text>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
          <footer-copyright />
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import FooterCopyright from "@/components/FooterCopyright.vue";

export default {
  components: {
    FooterCopyright
  },

  computed: {
    ...mapGetters(["getToken", "getGToken", "getResetUser"]),
  },

  mounted() {
    setInterval(() => {
      this.countdown();
    }, 1000);
  },

  data() {
    return {
      valid: false,
      error: {
        show: false,
        text: "",
      },
      isProcessing: false,
      otp_time: 900000,
      otp_time_output: null,
      read: false,
      otp: "",
      input: {
        digits: [],
      },

      rules: {
        digits: [(v) => !!v || "*"],
      },
    };
  },

  methods: {
    countdown() {
      if (this.otp_time > 0) {
        setTimeout(() => {
          let time = this.otp_time / 1000;
          let minutes = Math.floor(time / 60);
          let second = time - minutes * 60;
          this.otp_time_output = `${minutes} menit ${second} detik`;
          this.otp_time -= 1000;
        }, 1000);
      }
    },

    async resendOTP() {
      await this.axios
        .post("user/send-otp", {
          email_receiver: this.getResetUser.user_email,
          user_id: this.getResetUser.user_id,
        })
        .then(() => {
          this.isProcessing = false;
        })
        .catch((err_otp) => {
          console.log(err_otp);
        })
        .finally(() => (this.otp_time = 900000));
    },

    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    async handleSubmit() {
      const { valid } = await this.$refs.otp.validate();
      this.valid = valid;
      if (valid) {
        this.isProcessing = true;
        await this.axios
          .post("user/otp/verif", {
            user_id: this.getResetUser.user_id,
            otp: this.otp,
          })
          .then((response) => {
            if (!response.data.error) {
              this.$store.dispatch("stateResetPassword", true);
              this.isProcessing = false;
            }
          })
          .catch((error) => {
            switch (error?.response?.status) {
              case 400: {
                this.error.text = "Kodemu belum tepat. Silahkan ketik ulang.";
                this.error.show = true;
                break;
              }
              default: {
                this.error.text =
                  "Terjadi gangguan, silahkan kembali lagi nanti!";
                this.error.show = true;
              }
            }
          })
          .finally(() => (this.isProcessing = false));
      }
    },

    moveToNext(event, nextInputRef) {
      if (event.target.value.length === 1) {
        if (nextInputRef !== "input 7") {
          this.$refs[nextInputRef][0].focus();
        }
      }
    },
  },
};
</script>

<style scoped>
.inputNumber>>>input[type="text"] {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  color: rgb(var(--v-theme-primary));
}
</style>
