<template>
  <v-dialog v-model="dialogState" width="auto">
    <v-card max-width="700">
      <v-toolbar color="white">
        <v-btn icon="mdi-close" variant="text" @click="dialogState = false" />
        <v-toolbar-title> Filter </v-toolbar-title>
      </v-toolbar>

      <v-card-text v-if="filterEventDate" class="font-weight-bold"
        >Tanggal</v-card-text
      >
      <v-card-text v-if="filterEventDate" class="pt-0"
        >Pilih rentang tanggal event</v-card-text
      >
      <v-card-text v-if="filterEventDate">
        <v-row>
          <v-col cols="auto">
            <v-text-field
              v-model="data.startDate"
              type="date"
              class="me-2"
              label="Tanggal Mulai Acara"
              variant="outlined"
            ></v-text-field>
          </v-col>
          <v-col cols="auto">
            <v-text-field
              v-model="data.endDate"
              type="date"
              label="Tanggal Selesai Acara"
              variant="outlined"
            ></v-text-field
          ></v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="font-weight-bold">Harga</v-card-text>
      <v-card-text class="pt-0"
        >Masukkan rentang harga yang kamu inginkan</v-card-text
      >
      <v-card-text class="pt-0">
        <v-form ref="filterPrice">
          <v-range-slider
            v-model="data.price"
            :max="max"
            :min="min"
            step="10000"
            strict
            color="tertiary"
            :rules="rules.filterPrice"
            thumb-color="white"
          ></v-range-slider>
        </v-form>
      </v-card-text>
      <v-card-text class="pt-0">
        <v-row>
          <v-col cols="auto" class="me-auto">
            <v-text-field
              label="min"
              persistent-placeholder
              prefix="Rp"
              :max="max"
              :min="min"
              type="number"
              variant="outlined"
              density="compact"
              v-model="data.price[0]"
            >
              <template>
                {{ numberToRupiah(data.price[0]) }}
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="auto">
            <v-text-field
              label="max"
              persistent-placeholder
              prefix="Rp"
              :max="max"
              :min="min"
              type="number"
              variant="outlined"
              density="compact"
              v-model="data.price[1]"
            >
              <template>
                {{ numberToRupiah(data.price[1]) }}
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="font-weight-bold">Lokasi</v-card-text>
      <v-card-text class="pt-0"
        >Pilih tempat/lokasi event berlangsung</v-card-text
      >
      <v-card-text class="py-0">
        <div class="d-flex justify-start flex-wrap">
          <v-sheet v-for="(item, i) in locationData" :key="i" width="200">
            <v-checkbox
              v-model="data.location"
              :label="item"
              :value="item"
            ></v-checkbox>
          </v-sheet>
        </div>
      </v-card-text>

      <v-card-actions class="my-2 mx-4">
        <v-spacer></v-spacer>
        <v-btn
          @click="resetFilter"
          class="text-none"
          rounded="lg"
          variant="outlined"
          color="tertiary"
          >Reset</v-btn
        >
        <v-btn
          @click="filtering"
          class="text-none"
          rounded="lg"
          variant="flat"
          color="tertiary"
          >Terapkan</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { numberToRupiah } from "@/utils/numberFormatter";

export default {
  name: "FilterProduct",
  props: {
    filterEventDate: {
      type: [Boolean],
      default: false,
    },
    filterData: {
      type: Object,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100_000_000,
    },
    locationData: {
      type: Array,
      default: () => {
        return [
          "Jakarta",
          "Bekasi",
          "Surabaya",
          "Medan",
          "Bandung",
          "Depok",
          "Bali",
          "Palembang",
          "Yogyakarta",
          "Tangerang",
          "Makassar",
          "Balikpapan",
          "Semarang",
          "Cikarang",
          "Padang",
        ];
      },
    },
  },
  emits: ["update:dialog", "filtering"],
  data() {
    return {
      data: {
        category: null,
        startDate: null,
        endDate: null,
        price: [null, null],
        location: [],
      },
      dialogState: false,
      rules: {
        filterPrice: [
          (value) => {
            return (
              !value[0] ||
              !value[1] ||
              parseInt(value[0]) <= parseInt(value[1]) ||
              "Harga minimum lebih besar dari maximum"
            );
          },
        ],
      },
    };
  },
  methods: {
    numberToRupiah(number) {
      return numberToRupiah(number);
    },
    async filtering() {
      const { valid } = await this.$refs.filterPrice.validate();
      if (valid) {
        this.$emit("filtering", this.data);
        this.dialogState = false;
      }
    },

    resetFilter() {
      this.data = {
        category: null,
        date: null,
        price: [null, null],
        location: [],
      };

      this.$emit("filtering", this.data);
      this.dialogState = false;
    },
  },
  watch: {
    dialogState(newValue) {
      this.$emit("update:dialog", newValue);
    },
    dialog(newValue) {
      this.dialogState = newValue;
    },
    "data.price": {
      deep: true,
      async handler() {
        this.$refs.filterPrice.validate();
      },
    },
  },
};
</script>

<style>
</style>