<template>
  <div class="ps-3 d-flex justify-start align-self-start">
    <div class="d-flex">
      <v-img src="@/assets/logo.svg" width="28" height="18"></v-img>
      <p class="text-body-2 text-grey">{{ $t("footer.copyright") }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterCopyright",
};
</script>