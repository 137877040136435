<template>
  <v-footer app absolute class="d-flex flex-column w-100">
    <v-sheet height="100"></v-sheet>
    <div class="d-flex flex-wrap justify-space-evenly w-100 align-self-start">
      <v-sheet class="ma-2 pa-2 bg-color">
        <p class="text-h6 font-weight-bold">{{ $t("footer.e-tix.title") }}</p>
        <v-list v-model:selected="selected" class="bg-color" variant="plain">
          <v-list-item class="pa-0" v-for="(item, i) in column_one" :key="i" exact :to="{ name: item.name }">
            <v-list-item-title>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-sheet>

      <v-sheet class="ma-2 pa-2 bg-color">
        <p class="text-h6 font-weight-bold">{{ $t("footer.ticket.title") }}</p>
        <v-list v-model:selected="selected" variant="plain" class="bg-color">
          <v-list-item class="pa-0" v-for="item in column_two" :key="item" exact :to="{ name: item.name }">
            <v-list-item-title>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-sheet>

      <v-sheet class="ma-2 pa-2 bg-color">
        <p class="text-h6 font-weight-bold">{{ $t("footer.howto.title") }}</p>
        <v-list v-model:selected="selected" variant="plain" class="bg-color">
          <v-list-item class="pa-0" v-for="item in column_three" :key="item" exact :to="{ name: item.name }">
            <v-list-item-title>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-sheet>

      <v-sheet class="ma-2 pa-2 bg-color">
        <p class="text-h6 font-weight-bold">{{ $t("footer.follow.title") }}</p>

        <v-list v-if="!loading" v-model:selected="selected" variant="plain" class="bg-color">
          <v-list-item class="pa-0" v-for="item in column_four" :key="item" exact
            :prepend-icon="socialmedia[item.socialmedia_name]" @click="goToURL(item.socialmedia_link)">
            <v-list-item-title>
              {{ item.socialmedia_name }}
            </v-list-item-title>
          </v-list-item>
        </v-list>

        <template v-else v-for="index in 4" :key="index">
          <v-skeleton-loader type="list-item"></v-skeleton-loader>
        </template>
      </v-sheet>
    </div>

    <footer-copyright />
  </v-footer>
</template>

<script>
import FooterCopyright from "@/components/FooterCopyright.vue";
import SOCIALMEDIA from "@/config/socialmedia";

export default {
  name: "FooterTix",

  components: {
    FooterCopyright,
  },

  created() {
    this.getSocialMedia();
  },

  data() {
    return {
      selected: null,
      loading: false,
      column_one: [
        {
          title: this.$t("footer.e-tix.content.contact-us.title"),
          name: "contact",
        },
        {
          title: this.$t("footer.e-tix.content.pricing.title"),
          name: "price",
        },
        {
          title: this.$t("footer.e-tix.content.about.title"),
          name: "about",
        },
        {
          title: this.$t("footer.e-tix.content.help.title"),
          name: "help",
        },
      ],
      column_two: [
        {
          title: this.$t("footer.ticket.content.concert.title"),
          name: "music-concert",
        },
        {
          title: this.$t("footer.ticket.content.attraction.title"),
          name: "attraction",
        },
        {
          title: this.$t("footer.ticket.content.healing.title"),
          name: "healing-sejenak",
        },
        {
          title: this.$t("footer.ticket.content.race.title"),
          name: "race",
        },
      ],
      column_three: [
        {
          title: this.$t("footer.howto.content.buy-ticket.title"),
          name: "buy-ticket",
        },
        {
          title: this.$t("footer.howto.content.sell-ticket.title"),
          name: "sell-ticket",
        },
        {
          title: this.$t("footer.howto.content.subscription-kiosk.title"),
          name: "subscription-kiosk",
        },
      ],
      socialmedia: SOCIALMEDIA,
      column_four: [],
    };
  },

  methods: {
    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    goToURL(url) {
      window.open(url, "_blank");
    },
    getSocialMedia() {
      this.loading = true;
      this.axios
        .get(`info/sosmed/get`)
        .then((response) => {
          this.column_four = response.data.response.social_media;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style scoped>
.bg-color {
  background-color: #f8f8f8;
}
</style>