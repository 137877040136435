<template>
  <v-card
    class="text-center bg-color"
    :height="height"
    :width="width"
    variant="flat"
  >
    <v-img v-if="type == 'maintenance'" src="@/assets/maintenance.svg"></v-img>
    <v-img v-if="type == 'missing'" src="@/assets/missing_tiket.svg"></v-img>
    <v-img v-if="type == 'no_product'" src="@/assets/no_product.svg"></v-img>
    <v-img
      v-if="type == 'server_error'"
      src="@/assets/png/Internet-connection.png"
    ></v-img>
    <v-img
      v-if="type == 'page_not_found'"
      src="@/assets/page_not_found.svg"
    ></v-img>
    <v-card-item>
      <v-card-title class="text-wrap" style="word-break: break-word">{{
        title
      }}</v-card-title>
    </v-card-item>

    <v-card-text>
      {{ text }}
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        v-if="button"
        :to="to"
        @click="$emit('fetch')"
        color="primary"
        variant="tonal"
        >{{ button }}</v-btn
      >
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    button: {
      type: [Boolean, String],
      default: false,
    },
    to: {
      type: Object,
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
    },
    width: {
      type: [String, Number],
      default: "auto",
    },
    height: {
      type: [String, Number],
      default: "auto",
    },
    type: {
      type: String,
      default: "maintenance",
    },
  },
};
</script>

<style>
</style>