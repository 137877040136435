<template>
  <v-row no-gutters align="center" class="bg-color" justify="center">
    <v-col cols="12" class="d-flex justify-center">
      <v-sheet class="bg-color" width="350">
        <v-img
          @contextmenu.prevent
          src="@/assets/04_ilustration_no_product_found.svg"
          lazy-src="@/assets/04_ilustration_no_product_found.svg"
          width="400"
        />
        <p class="text-h6 font-weight-bold text-center">
          Maaf, Kami Tidak Menemukan Yang Kamu Cari
        </p>
        <p class="text-body-2 font-weight-medium text-center">
          Silahkan gunakan keyword pencarian lainnya atau sesuaikan filter
        </p>
      </v-sheet>
    </v-col>
  </v-row>
</template>