<template>
  <v-container class="pa-0">
    <v-row :style="{ height: '100vh' }" class="justify-center align-center ma-auto">
      <v-col cols="12">
        <v-sheet max-width="1000" class="mx-auto">
          <v-card elevation="6">
            <v-row no-gutters align="center" justify="center">
              <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                <router-link :to="{ name: 'home' }">
                  <v-img @contextmenu.prevent class="mx-auto" src="@/assets/logo.svg" width="180" height="100"></v-img>
                </router-link>

                <v-img @contextmenu.prevent class="mx-auto" src="@/assets/png/forgot-password.png" width="350"
                  height="350"></v-img>
              </v-col>

              <v-col cols="12" md="6">
                <v-card-text>
                  <ErrorHandling class="mx-10 my-2" v-if="error" :error="error" />
                  <p class="text-h6 text-primary font-weight-bold">
                    Atur Ulang Kata Sandi
                  </p>
                  <p class="text-body-2 text-grey">
                    Silahkan atur ulang kata sandi. Sebaiknya jangan gunakan tanggal
                    lahir atau kata sandi yang mudah digunakan orang lain seperti
                    12345
                  </p>
                  <v-divider class="my-2"></v-divider>
                  <v-row align="center">
                    <v-col cols="12" sm="11">
                      <v-form ref="password" v-model="valid" lazy-validation>
                        <v-text-field @click.right.prevent @copy.prevent @paste.prevent
                          @keydown.enter.prevent="handleSubmit()" v-model="input.password" :rules="rules.password"
                          hint="Kata sandi minimal 8 digit gabungan huruf besar, huruf kecil, angka dan simbol"
                          :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                          @click:append-inner="visible = !visible" :type="visible ? 'text' : 'password'"
                          label="Kata Sandi" variant="outlined" dense color="primary" required />
                        <v-text-field @click.right.prevent @copy.prevent @paste.prevent
                          @keydown.enter.prevent="handleSubmit()" v-model="input.retype_password"
                          :rules="rules.retype_password" label="Konfirmasi Kata Sandi" hint="Ketik ulang kata sandimu"
                          variant="outlined" dense color="primary" type="password" required />

                        <v-btn :loading="isProcessing" :disabled="isProcessing || !valid" @click.prevent="handleSubmit"
                          class="text-capitalize" :color="isProcessing || !valid ? 'grey' : 'primary'" block>
                          Kirim
                        </v-btn>
                      </v-form>

                      <v-card-text>
                        <h6 class="text-center text-grey">
                          Butuh bantuan?
                          <v-btn class="font-weight-bold text-none pa-0" size="x-small" color="primary" variant="text"
                            :to="{ name: 'help' }">Tanyakan pada kami</v-btn>
                        </h6>
                      </v-card-text>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
          <footer-copyright />
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>

  <v-snackbar snackbar color="white" v-model="snackbar.status" rounded="pill">
    <div class="text-black">
      {{ snackbar.text }}
    </div>

    <template v-slot:actions>
      <v-btn color="pink" rounded="pill" variant="text" @click="snackbar.status = false">
        Tutup
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import ErrorHandling from "@/components/Error.vue";
import isStrongPassword from "validator/lib/isStrongPassword";
import { mapGetters } from "vuex";
import FooterCopyright from "@/components/FooterCopyright.vue";

export default {
  components: {
    ErrorHandling,
    FooterCopyright,
  },

  computed: {
    ...mapGetters(["getResetUser"]),
  },

  data() {
    return {
      valid: false,
      isProcessing: false,
      visible: false,
      input: {
        password: "",
        retype_password: "",
      },
      rules: {
        password: [
          (v) => !!v || "Kata sandi wajib diisi",
          (v) => (v && v.length >= 8) || "Kata sandi kurang dari 8 karakter",
          (v) =>
            isStrongPassword(v) ||
            "Password harus terdiri atas huruf kapital, huruf kecil, simbol, dan angka",
        ],
        retype_password: [
          (v) => !!v || "Kata sandi wajib diisi",
          (v) => v === this.input.password || "Kata sandi harus sama",
        ],
      },
      snackbar: {
        status: false,
        text: "",
      },
    };
  },

  methods: {
    async handleSubmit() {
      this.isProcessing = true;
      const { valid } = await this.$refs.password.validate();
      if (valid) {
        this.isProcessing = true;
        this.axios
          .put("user/password/reset", {
            user_id: this.getResetUser.user_id,
            password: this.input.password,
          })
          .then((response) => {
            this.showSnackbar(response.data.message);
            setTimeout(() => {
              this.$router.push("/login");
              this.$store.dispatch("stateResetEmail", true);
              this.isProcessing = false;
            }, 1000);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => (this.isProcessing = false));
      }
    },

    showSnackbar(text) {
      this.snackbar.status = true;
      this.snackbar.text = text;
    },
  },
};
</script>

<style scoped></style>
