<template>
  <div class="d-flex justify-center">
    <v-sheet width="350" class="bg-color">
      <v-img
        @contextmenu.prevent
        src="@/assets/server_error.svg"
        lazy-src="@/assets/server_error.svg"
        width="400"
      ></v-img>
      <p class="text-h6 font-weight-bold text-center">
        Maaf, Ada Kesalahan Server
      </p>
    </v-sheet>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>